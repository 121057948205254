<template>
  <div class="pointsList">
    <v-list
      :finished="finished"
      @onLoad="onLoad"
      :finishedText="finishedText"
      ref="list"
    >
      <div v-for="(item, index) in listData" :key="index" class="oItem">
        <div class="line"></div>
        <div class="oItem-l">
          <div class="title">{{ getType(item) }}</div>
          <div class="time">{{ item.insertTime }}</div>
        </div>
        <div class="oItem-r">
          <div :class="item.operationType == 0 ? 'orange' : 'black'">
            {{ item.operationType == 0 ? "-" : "+" }}
          </div>
          <div :class="item.operationType == 0 ? 'orange' : 'black'">
            {{ item.pointCount }}
          </div>
        </div>
      </div>
    </v-list>
  </div>
</template>

<script>
import { pointsList } from "./api.js";
import { getOption, formatStr } from "@/utils/utils.js";
import { pointType } from "./map.js";
var moment = require("moment");
export default {
  name: "pointsList",
  data() {
    return {
      finishedText: "没有更多了",
      finished: false,
      listData: [],
      requestData: {
        curPage: 1,
        pageSize: 20,
      },
    };
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },

    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  mounted() {
    // this.getActivityList();
  },
  methods: {
    formatStrFunc(str) {
      console.log(formatStr(str));
      if (str) {
        return formatStr(str);
      }
    },
    getType(item) {
      if (item.recordType || item.recordType == 0) {
        return getOption(item.recordType, pointType, "key").value;
      } else {
        return "";
      }
    },
    onLoad() {
      this.getPointsList();
    },
    getPointsList() {
      let params = {
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
        userId: this.userId,
        tenantId: this.tenantId,
      };
      this.$axios
        .post(`${pointsList}`, params, {
          headers: { "Content-Type": "application/json; charset=UTF-8" },
        })
        .then((res) => {
          if (res.code === 200) {
            if (res.data.pages <= res.data.current) {
              this.finished = true;
            } else {
              this.$refs.list._data.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.finishedText = "";
              return;
            }
            res.data.records.forEach((item) => {
              this.listData.push(item);
            });
          } else {
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
.pointsList {
  min-height: 100vh;
  background: #f7f7f7;
  .oItem {
    background: #fff;
    padding: 24px 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    position: relative;
    .line {
      position: absolute;
      bottom: 1px;
      right: 0;
      width: 718px;
      height: 1px;
      background: rgba(0, 0, 0, 0.06);
    }
    .oItem-l {
      font-size: 28px;
      line-height: 48px;
      .title {
        font-size: 32px;
        color: #333333;
      }
      .time {
        font-size: 24px;
        color: #cccccc;
      }
    }
    .oItem-r {
      display: flex;
      align-items: center;
      font-size: 36px;
      .orange {
        color: #fe6f16;
      }
      .black {
        color: black;
      }
    }
  }
}
</style>
